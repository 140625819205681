import React from "react";
import hero from "../images/light-bulbs-5488573_1280.webp";
import { ReactTyped } from "react-typed";
export const Home = () => {
  return (
    <div class="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
      <div class="absolute inset-0">
        <img
          src={hero}
          alt="Background Img"
          class="object-cover object-center w-full h-full"
        />
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>

      <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
        <h1 class="text-2xl md:text-5xl font-bold leading-tight mb-4">
          Pioneering Technology Excellence
        </h1>

        <div >
          <div className="text-xl mb-8 flex flex-col sm:flex-row gap-3 items-center">
            <p>We</p>
            <ReactTyped 
              strings={["Craft", "Tailor", "Offer"]}
              typeSpeed={100}
              backSpeed={150}
              attr="placeholder"
              loop
            >
              <input className="rounded-sm text-xl bg-blue w-20" type="text" />
            </ReactTyped>
            <p>Exceptional Software That Propels Your Vision</p>
          </div>
        
          <a
            href="about"
            className="bg-indigo-700 rounded-sm text-white hover:bg-indigo-800 py-3.5 px-6 text-lg font-semibold transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
          >
            About our company
          </a>
        </div>
      </div>
    </div>
  );
};
