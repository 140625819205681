import React from "react";
import contImg from "../images/timothy.jpg";
import { FaLocationDot } from "react-icons/fa6";

export const Contact = () => {
  return (
    <section className="w-full overflow-hidden">
      <div class="relative bg-gradient-to-r from-purple-600 to-indigo-700 h-screen text-white overflow-hidden">
        <div class="absolute inset-0">
          <img
            src={contImg}
            alt="Background img"
            class="w-full img-fluid h-full object-cover"
          />
        </div>

        <div class="relative z-10 flex flex-col justify-center items-center h-full text-center">
          <h1 class="md:text-5xl text-2xl font-bold leading-tight mb-4">
            Contact for Support
          </h1>
        </div>
      </div>

      <section data-aos-duration="4000" data-aos="fade-up" class="bg-indigo-70 dark:bg-slate-800" id="contact">
        <div class="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8 ">
          <div data-aos-duration="4000" data-aos="fade-up" class="my-4">
            <div class="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
              <h2 class="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl">
                Get in Touch
              </h2>

              <p class="mx-auto mt-4 max-w-3xl text-xl text-gray-600 dark:text-slate-400">
                Get in touch with us for any kind of software and related assistance.
              </p>
            </div>
          </div>
          <div class="flex items-stretch justify-center">
            <div class="grid md:grid-cols-2">
              <div data-aos-duration="4000" data-aos="fade-right" class="h-full">
                <p class="mt-3 mb-8 text-lg text-gray-600 dark:text-slate-400">
                For your continued journey with us, you can checkout our Location, Contact Information and Email Address
                </p>
                <ul class="mb-6 md:mb-0">
                  <li class="flex">
                    <div class="flex h-8 w-8 items-center justify-center rounded bg-indigo-700 text-white">
                      <FaLocationDot />
                    </div>
                    <div class="mx-2 mb-4">
                      <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">
                        Our Address
                      </h3>
                      <p class="text-gray-600 dark:text-slate-400">
                        1230 Bombo Road
                      </p>
                      <p class="text-gray-600 dark:text-slate-400">
                       Kampala, Uganda
                      </p>
                    </div>
                  </li>
                  <li class="flex">
                    <div class="flex h-8 w-8 items-center justify-center rounded bg-indigo-700 text-white">
                      <FaLocationDot />
                    </div>
                    <div class="mx-2 mb-4">
                      <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">
                        Contact Information
                      </h3>
                      <p class="text-gray-600 dark:text-slate-400">
                        Mobile: +256 761 749-664, +256 706 579-773
                      </p>
                      <p class="text-gray-600 dark:text-slate-400">
                       Email Address: softwareelites.ug@gmail.com
                      </p>
                    </div>
                  </li>
                  <li class="flex">
                    <div class="flex h-8 w-8 items-center justify-center rounded bg-indigo-700 text-white">
                      <FaLocationDot />
                    </div>
                    <div class="mx-2 mb-4">
                      <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 dark:text-white">
                        Working hours
                      </h3>
                      <p class="text-gray-600 dark:text-slate-400">
                        Monday - Friday: 8:00am - 5:00pm
                      </p>
                      <p class="text-gray-600 dark:text-slate-400">
                        Saturday: 9:00am - 4:00pm
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div data-aos-duration="4000" data-aos="fade-left" class="card h-fit max-w-6xl py-5 md:py-12" id="form">
                <h2 class="mb-4 text-2xl font-bold dark:text-white">
                  Contact For Any Assistance
                </h2>
                <form id="contactForm">
                  <div class="mb-6">
                    <div class="mx-0 mb-1 sm:mb-4">
                      <div class="mx-0 mb-1 sm:mb-4">
                        <label
                          for="name"
                          class="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <input
                          type="text"
                          id="name"
                          autocomplete="given-name"
                          placeholder="Your name"
                          class="mb-2 w-full rounded-sm border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                          name="name"
                        />
                      </div>
                      <div class="mx-0 mb-1 sm:mb-4">
                        <label
                          for="email"
                          class="pb-1 text-xs uppercase tracking-wider"
                        ></label>
                        <input
                          type="email"
                          id="email"
                          autocomplete="email"
                          placeholder="Your email address"
                          class="mb-2 w-full rounded-sm border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                          name="email"
                        />
                      </div>
                    </div>
                    <div class="mx-0 mb-1 sm:mb-4">
                      <label
                        for="textarea"
                        class="pb-1 text-xs uppercase tracking-wider"
                      ></label>
                      <textarea
                        id="textarea"
                        name="textarea"
                        cols="30"
                        rows="5"
                        placeholder="Write your message..."
                        class="mb-2 w-full rounded-sm border border-gray-400 py-2 pl-2 pr-4 shadow-md dark:text-gray-300 sm:mb-0"
                      ></textarea>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      type="submit"
                      class="w-full bg-indigo-700 text-white px-6 py-3 font-xl rounded-sm sm:mb-0"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div data-aos-duration="4000" data-aos="fade-up" className="py-12 mx-auto">
            <iframe title="softwareelites map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7085431031446!2d32.534405774807084!3d0.42502866385069893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dafc5842241ab%3A0x14b85130af6c2207!2sSoftware%20elites!5e0!3m2!1sen!2sug!4v1726726901716!5m2!1sen!2sug"
              width="100%"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </section>
  );
};






