import React, { useState } from "react";
import Logo from "../images/Group 12.png";
import { Link } from "react-router-dom";
export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
   <div div> 
    <nav className="shadow-md bg-indigo-600 fixed top-0 left-0 w-full z-50">
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div style={{ width: "150px" }}>
          <Link to="/" className="flex items-center">
            <img className="w-1/4" src={Logo} alt="Logo" />
          </Link>
        </div>

        <div className="flex items-center md:w-1/2 sm:w-1/2">
          <button
            id="menu-toggle"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-md text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:hover:bg-black dark:focus:ring-gray-600 md:hidden"
            onClick={handleMenuToggle}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>

        <div
          className={`w-full md:block md:w-auto ${menuOpen ? "" : "hidden"}`}
          id="mobile-menu"
        >
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-md md:font-medium">
            <li>
              <a
               href="/about"
                className="block py-2 navlinks pr-4 pl-3 text-white md:border-0 md:p-0"
              >
                About
              </a>
            </li>
            <li>
              <a
               href="/services"
                className="block py-2 navlinks pr-4 pl-3 text-white md:border-0 md:p-0"
              >
                Services
              </a>
            </li>
            <li>
                       <a
                href="/contact"
                className="block py-2 navlinks pr-4 pl-3 text-white md:border-0 md:p-0"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </div>
  );
};
