import React from "react";
import { Link } from "react-router-dom";

const Notfound = () => {
  return (
    <div class="flex text-center items-center justify-center h-screen w-full p-4 rounded-lg shadow sm:p-6 md:p-8 ">
      <div className="leading-tight">
        <h1 className="leading-tight text-7xl">404</h1>
        <h4 className="text-2xl">Page Not Found</h4>
        <div>
          <Link
            className="mt-4 inline-block border border-purple-700 rounded-sm px-4 py-4 hover:border-transparent bg-indigo-700 rounded-sm text-white transition-all duration-300"
            to="/"
          >
            Go back to Home Page
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Notfound;
